var formsWithDatepicker = [], priceRequest;

jQuery(function ($) {
    // DatePicker validation fix
    $('.krajee-datepicker').each(function (k, v) {
        formsWithDatepicker.push($(v).closest('form'));
    });

    formsWithDatepicker = $.unique(formsWithDatepicker);
    $(formsWithDatepicker).each(function (k, form) {
        form.on('afterValidateAttribute', function (e, data) {
            let input = jQuery(data.container).find('.krajee-datepicker');
            if (input.length) {
                input.removeClass('is-valid').removeClass('is-invalid');
                input.addClass(jQuery(data.input).get(0).className);
            }
        })
    });

    // Translit
    $(document.body).on('keyup', '[data-transliterate-to]', function () {
        var selector = $(this).data('transliterate-to'), el = $(selector);
        if (el.length && typeof cyrillicToTranslit != 'undefined') {
            el.val(cyrillicToTranslit().transform($(this).val()));
        }
    });

    // Price calculation
    $('body').on('change', '.order-form [data-price-trigger]', function () {
        priceRequest = $.ajax({
            url: '/order/ajax',
            data: $('.order-form form').serialize(),
            type: 'POST',
            beforeSend: function () {
                if (priceRequest != null) {
                    priceRequest.abort();
                }
            },
            success: function (response) {
                $('[data-price]').text(response.price || '-');
            }
        })
    });

    // Toggle order form buttons
    checkButtons()
    $(document.body).on('change', '[data-vehicle-selector], [data-datum-selector], [data-address-selector]', checkButtons)


    // Order form popup settings
    const ajaxPopup = $('#ajaxPopup');
    $(document).on('click', '[data-show-modal]', function () {
        ajaxPopup.find('.modal-content-inner').text('');

        if (!ajaxPopup.data('bs.modal').isShown) {
            ajaxPopup.modal('show');
        }
        var url = $(this).data('value'), selector = $(this).data('selector');
        if (selector && $(selector).length) {
            url += '/' + $(selector).val();
        }

        ajaxPopup
            .find('.modal-content-inner')
            .load(url, function () {
                attachFormEvent(ajaxPopup.find('form'), function (response) {
                    if ('type' in response) {
                        const selector = $('[data-' + response.type + '-selector]');
                        if (selector.length) {
                            selector.find('option:gt(0)').remove().end().append(generateOptions(response.options))
                            selector.val(response.id).change();
                        }
                    }
                    ajaxPopup.modal('hide');
                });
            })
            .end().find('.modal-title').html($(this).data('title'));
    });

    $(document).on('pjax:success', function (data, status, xhr, options) {
        console.log(data, status, xhr, options);
        return false;
    })
});

function generateOptions(options) {
    let result = '';
    $.each(options, function (key, data) {
        result += '<option value="' + data[0] + '">' + data[1] + '</option>';
    })
    return result;
}

function attachFormEvent(form, callback) {
    form.on('beforeSubmit', function (e) {
        e.preventDefault();
        jQuery.ajax({
            url: form.attr('action'),
            type: form.attr('method'),
            data: new FormData(form[0]),
            mimeType: 'multipart/form-data',
            contentType: false,
            cache: false,
            processData: false,
            dataType: 'json',
            success: function (data) {
                if (callback) {
                    callback(data);
                }
            }
        });
        return false;
    });
}

function checkButtons() {
    $.each(['vehicle', 'datum', 'address'], function (k, v) {
        if ($('[data-' + v + '-selector]').val() === '') {
            $('.' + v + '-add-button').removeClass('d-none').addClass('d-block');
            $('.' + v + '-edit-button').addClass('d-none').removeClass('d-block');
        } else {
            $('.' + v + '-add-button').addClass('d-none').removeClass('d-block');
            $('.' + v + '-edit-button').removeClass('d-none').addClass('d-block');
        }
    })
}
